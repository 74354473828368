<template>
  <div class="carModel">
    <v-dialog max-width="600" v-model="loadingUpd" persistent>
      <v-card-title style="background-color: white; text-align:center">
        Uploading, Please wait...
      </v-card-title>
      <v-container fluid fill-height style="background-color: white">
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>

    <!-- delete dialog box -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete this car model:
          <b>{{ delCarModel.name }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelCarModel()">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add car dialog box -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Car Model
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="addCarModel.name" label="Name"></v-text-field>
          <v-text-field
            v-model="addCarModel.description"
            label="Description"
          ></v-text-field>
          <v-file-input
            @change="chooseImage"
            v-model="imgFile"
            clearable
            label="Logo"
            accept="image/jpg,image/jpeg,image/png,image/gif"
          ></v-file-input>
          <v-switch
            v-model="addCarModel.recommendFlag"
            class="ma-2"
            label="Featured"
          ></v-switch>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doAddCarModel()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog box -->
    <v-dialog v-model="editDialog" max-width="800px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Car Model

          <!-- extras dialog box -->
          <CarModelExtrasDialog
            :modelId="editCarModel.id"
            :fuelTypeName="fuelTypeName"
            @error="showSnack('Update extras failed' + $event, 'error')"
          />

          <v-switch
            style="float: right; margin-left: auto;"
            label="Emp Check"
            color="success"
            v-model="checkToggle"
            @change="onToggleCheck(editCarModel.id)"
            :disabled="checkToggle"
          ></v-switch>
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            v-model="editCarModel.carBrandId"
            :items="carBrandList"
            item-text="name"
            item-value="id"
            :placeholder="editCarModel.carBrandName"
            prepend-icon="mdi-database-search"
          >
          </v-autocomplete>
          <v-text-field
            v-model="editCarModel.modelName"
            label="Model Name"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.subModelName"
            label="Submodel Name"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.modelYear"
            label="Model Year"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.faceliftedYear"
            label="Facelifted Year"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.modelEndYear"
            label="Model End Year"
          ></v-text-field>
          <v-select
            v-model="editCarModel.carTypeId"
            :items="carTypeList"
            item-value="id"
            item-text="name"
            label="Vehical Type"
          >
          </v-select>
          <v-select
            v-model="editCarModel.pastCarModel"
            :items="selectionChoice"
            item-value="value"
            item-text="name"
            label="Past Car Model"
          >
          </v-select>
          <v-select
            v-model="editCarModel.adPiModel"
            :items="selectionChoiceAdPi"
            item-value="value"
            item-text="name"
            label="AD or PI model"
          >
          </v-select>
          <v-select
            v-model="editCarModel.coeCategoryId"
            :items="coeCatList"
            item-value="id"
            item-text="name"
            label="COE Category"
          >
          </v-select>
          <v-select
            v-model="editCarModel.fuelTypeId"
            :items="carFuelTypeList"
            item-value="id"
            item-text="name"
            label="Fuel Type"
          >
          </v-select>
          <v-text-field
            v-model="editCarModel.estimatedPrice"
            label="Estimated Price"
          ></v-text-field>
          <!-- <v-text-field v-model="editCarModel.name" label="Name"></v-text-field>
          <v-text-field
            v-model="editCarModel.doorNum"
            label="Door Num"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.editionName"
            label="Edition Name"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.efficiency"
            label="Efficiency"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.engine"
            label="Engine"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.estimatedDepreciation"
            label="Estimated Depreciation"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.horsePower"
            label="Hourse Power"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.manuYear"
            label="Manu Year"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.roadTax"
            label="Road Tax"
          ></v-text-field>
          <v-text-field
            v-model="editCarModel.transmission"
            label="Transmission"
          ></v-text-field> -->
        </v-card-text>
        <hr />
        <hr />
        <br />
        <div class="textarea-container">
          <p>Description</p>
          <v-textarea
            v-model="editCarModel.description"
            solo
            label="Description"
            :rows="2"
            auto-grow
          />
          <p>Top Gear Review (Pros)</p>
          <v-textarea
            v-model="editCarModel.modelPros"
            solo
            label="Top Gear Review (Pros)"
            :rows="2"
            auto-grow
          />
          <p>Top Gear Review (Cons)</p>
          <v-textarea
            v-model="editCarModel.modelCons"
            solo
            label="Top Gear Review (Cons)"
            :rows="2"
            auto-grow
          />
          <p>Carbuyer Review</p>
          <v-textarea
            v-model="editCarModel.carbuyerReview"
            solo
            label="Carbuyer Review"
            :rows="2"
            auto-grow
          />
          <v-row>
            <v-col class="py-0">
              <ImgGroupInput ref="editImgs" :imgProps="editImgs" />
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditCarModel()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- search box -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchType()"
          v-model="nameSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchType()"
          @keyup.enter.native="searchType()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
      <v-col>
        <AddNewModelDialog
          :coe-cat-list="coeCatList"
          :vesCategoryList="vesCategoryList"
          :car-brand-list="carBrandList"
          :car-type-list="carTypeList"
          :carFuelTypeList="carFuelTypeList"
          :transmissionList="transmissionList"
          @add="add"
          @error="showSnack('Add Car Failed' + $event, 'error')"
        />
      </v-col>
    </v-row>

    <!-- pagination table -->
    <v-data-table
      :headers="headers"
      :items="carModel"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td><img :src="row.item.displayImgs[0]" style="width: 50px;" /></td>

          <!-- car model:: -->
          <td>
            <span style="color: red">{{ row.item.carBrandName }}</span> &nbsp;
            <span style="color: green">{{ row.item.modelName }}</span> &nbsp;
            <span style="color: orange">{{ row.item.subModelName }}</span>
          </td>
          <!--  -->
          <td>{{ row.item.modelYear }} - {{ row.item.modelEndYear }}</td>
          <td>{{ row.item.faceliftedYear }}</td>
          <td>{{ row.item.carTypeName }}</td>
          <td>{{ row.item.fuelTypeName }}</td>
          <!-- past/new car Model -->
          <td>{{ row.item.pastCarModel }}</td>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.status }}</td>
          <!-- <td>{{ row.item.description }}</td> -->
          <!-- <td>
            <img
              style="height:50px; padding-top: 6px"
              v-if="row.item.logoPath != null"
              v-bind:src="
                'https://ucars-bucket.s3-ap-southeast-1.amazonaws.com/' +
                  row.item.logoPath
              "
            />
          </td> -->
          <!-- <td>{{ row.item.doorNum }}</td> -->
          <!-- <td>{{ row.item.horsePower }}</td> -->
          <!-- tbl row edit btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <!-- tbl row del btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
          <td>
            {{
              row.item.createTime ? row.item.createTime.substring(0, 19) : "-"
            }}
          </td>
          <td>
            {{
              row.item.updateTime ? row.item.updateTime.substring(0, 19) : "-"
            }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>
<style scoped>
.textarea-container {
  font-size: 12px;
  padding: 24px;
}
</style>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import uploadImage from "../../../services/image-uploader";
import ImgGroupInput from "@/components/ImgGroupInput";

import postAxios from "../../../services/axios-post";
import putAxios from "../../../services/axios-put";
import getAxios from "../../../services/axios-get";
import deleteAxios from "../../../services/axios-delete";

import createWatermark from "../../../services/create-watermark";
import uploadSignedImage from "../../../services/aws-upload-image";

import CarModelExtrasDialog from "@/components/CarModelExtrasDialog";
import AddNewModelDialog from "@/components/AddNewModelDialog";

import createSignedurl from "../../../services/create-singed-url";

Vue.use(VueAxios, axios);

export default {
  components: {
    CarModelExtrasDialog,
    AddNewModelDialog,
    ImgGroupInput,
  },
  data() {
    return {
      loadingUpd: false,
      selectionChoice: [
        {
          name: "Yes",
          value: "Yes",
        },
        {
          name: "No",
          value: "No",
        },
      ],
      selectionChoiceAdPi: [
        {
          name: "AD",
          value: "AD",
        },
        {
          name: "PI",
          value: "PI",
        },
        {
          name: "Both",
          value: "Both",
        },
      ],
      checkToggle: false,
      isLoading: false,
      search: null,
      entries: [],

      type: null,
      alertMsg: null,
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      imgUrl: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      addCarModel: {
        name: null,
        description: null,
        logoPath: null,
        recommendFlag: 0,
      },

      editCarModel: {
        id: null,
        carBrandId: null,
        modelName: null,
        subModelName: null,
        modelYear: null,
        faceliftedYear: null,
        modelEndYear: null,
        carTypeId: null,
        pastCarModel: null,
        adPiModel: null,
        coeCategoryId: null,
        description: null,
        modelPros: null,
        modelCons: null,
        carbuyerReview: null,
        displayImgs: null,
        fuelTypeId: null,
        // not used below
        name: null,
        baseName: null,
        doorNum: null,
        editionName: null,
        efficiency: null,
        engine: null,
        estimatedDepreciation: null,
        estimatedPrice: null,
        horsePower: null,
        manuYear: null,
        roadTax: null,
        transmission: null,
        checkedEmp: null,
        //
      },
      fuelTypeName: null,
      delCarModel: {
        id: "",
        name: "",
      },
      nameSearch: "",
      total: 0,
      dialog: false,
      editDialog: false,
      // extrasDialog: false,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Display Img", value: "displayImgs" },
        { text: "Car Model (Brand, Model Name, Submodel Name)", value: "name" },
        { text: "Model Year", value: "modelYear" },
        { text: "Face Lift Year", value: "faceLiftYear" },
        { text: "Car Type", value: "carTypeName" },
        { text: "Fuel Type", value: "fuelTypeName" },
        { text: "Past/New Car Model", value: "pastNewCarModel" },
        { text: "Id", value: "id" },
        { text: "Status", value: "status" },
        { text: "Edit" },
        { text: "Delete" },
        { text: "Created Time" },
        { text: "Updated Time" },
      ],
      carModel: [],
      coeCatList: [],
      vesCategoryList: [],
      carBrandList: [],
      carTypeList: [],
      carFuelTypeList: [],
      transmissionList: [],
      editImgs: [],
    };
  },
  computed: {
    items() {
      return this.entries;
    },
  },
  watch: {
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      console.log("Loading here: " + val);

      // Lazily load input items
      fetch(
        `https://hades.ucars.sg/relatedDataSystem/carModelBaseName?searchContent=${val}`
      )
        .then(res => res.json())
        .then(res => {
          // const { count, entries } = res;
          // this.count = count;
          this.entries = res.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.fetchCredentials();
    this.fetchCarBrand();
    this.fetchCarModel();
    this.fetchCarType();
    this.fetchFuelType();
    this.fetchCOECat();
    this.fetchVes();
  },
  methods: {
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchCarModel();
    },
    delButtonClick(item) {
      this.delCarModel.id = item.id;
      this.delCarModel.name = item.name;
      this.deleteDialog = true;
    },
    editButtonClick(item) {
      this.fuelTypeName = item.fuelTypeName;
      this.editCarModel.id = item.id;
      this.editCarModel.modelName = item.modelName;
      this.editCarModel.subModelName = item.subModelName;
      this.editCarModel.faceliftedYear = item.faceliftedYear;
      this.editCarModel.modelEndYear = item.modelEndYear;
      this.editCarModel.pastCarModel = item.pastCarModel;
      this.editCarModel.adPiModel = item.adPiModel;
      this.editCarModel.name = item.name;
      this.editCarModel.baseName = item.baseName;
      this.editCarModel.description = item.description;
      this.editCarModel.modelYear = item.modelYear;
      this.editCarModel.doorNum = item.doorNum;
      this.editCarModel.editionName = item.editionName;
      this.editCarModel.efficiency = item.efficiency;
      this.editCarModel.engine = item.engine;
      this.editCarModel.estimatedDepreciation = item.estimatedDepreciation;
      this.editCarModel.estimatedPrice = item.estimatedPrice;
      this.editCarModel.horsePower = item.horsePower;
      this.editCarModel.manuYear = item.manuYear;
      this.editCarModel.modelPros = item.modelPros;
      this.editCarModel.modelCons = item.modelCons;
      this.editCarModel.carbuyerReview = item.carbuyerReview;
      this.editCarModel.roadTax = item.roadTax;
      this.editCarModel.fuelTypeId = item.fuelTypeId;
      this.editCarModel.coeCategoryId = item.coeCategoryId;
      this.editCarModel.carBrandId = item.carBrandId;
      this.editCarModel.carTypeId = item.carTypeId;
      this.editCarModel.transmission = item.transmission;
      this.editCarModel.checkedEmp = item.checkedEmp;

      this.checkToggle = this.editCarModel.checkedEmp != null;

      this.editImgs = [...item.displayImgs].map(url => ({
        url,
      }));
      this.editDialog = true;
    },
    doDelCarModel() {
      const self = this;
      const param = {
        carModelId: self.delCarModel.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/delCarModel`,
        param,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchCarModel();
            self.showAlert("success", "Car Model Deleted Successfully!");
          }
        })
        .catch(e => {
          self.showAlert("error", e);
        });
    },
    async doEditCarModel() {
      const self = this;

      try {
        // const imgResList = [...(await self.$refs.editImgs.upload())];
        // self.editCarModel.defaultImgPath = imgResList.shift();
        // self.editCarModel.displayImgs = imgResList;
        self.loadingUpd = true;
        const imgResLists = self.$refs.editImgs.imgs;
        let uploadUrlImageName = [];
        let img;
        for (let i = 0; i < imgResLists.length; i++) {
          img = imgResLists[i];
          if (!img.file) {
            if (
              img.url.indexOf("_wm.") === -1 &&
              self.editCarModel.watermarkFlag
            ) {
              const uploadUrl = await createSignedurl(img.url, this.token);
              console.log(uploadUrl);
              const watermarkedImage = await createWatermark(img.url);
              uploadUrlImageName.push(uploadUrl.data.waterMarkedName);
              await uploadSignedImage(
                watermarkedImage,
                uploadUrl.data.waterMarkedUrl
              );
            } else {
              uploadUrlImageName.push(img.url);
            }
          } else if (img.file) {
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: "png",
                waterMark: self.editCarModel.watermarkFlag,
              },
              this.token
            );
            if (self.editCarModel.watermarkFlag) {
              const watermarkedImage = await createWatermark(img.file);
              uploadUrlImageName.push(uploadUrl.data.waterMarkedName);
              if (watermarkedImage) {
                await uploadSignedImage(
                  watermarkedImage,
                  uploadUrl.data.waterMarkedUrl
                );
              }
            } else {
              uploadUrlImageName.push(uploadUrl.data.originalName);
              await uploadSignedImage(img.file, uploadUrl.data.originalUrl);
            }
          }
        }

        self.editCarModel.defaultImgPath = uploadUrlImageName.shift();
        self.editCarModel.displayImgs = uploadUrlImageName;
        self.editCarModel.watermarkFlag = 0;
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/updCarModel`,
          self.editCarModel,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.editDialog = false;
              self.fetchCarModel();
              self.showAlert("success", "Car Model Edited Successfully!");
            }
            self.loadingUpd = false;
          })
          .catch(e => {
            self.showAlert("error", e);
          });
        self.loadingUpd = false;
      } catch (e) {
        self.showAlert("error", e);
        console.error(e);
      }
    },
    async doAddCarModel() {
      const self = this;
      try {
        var attachmentPath = null;
        if (self.imgFile) {
          const imgRes = await uploadImage(self.imgFile);
          attachmentPath = imgRes.data.data;
        }
        let featured = 0;
        if (self.addCarModel.recommendFlag) {
          featured = 1;
        }
        const params = {
          name: self.addCarModel.name,
          description: self.addCarModel.description,
          logoPath: attachmentPath ? attachmentPath : null,
          recommendFlag: featured,
        };
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/addCarModel`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.dialog = false;
              self.fetchCarModel();
              self.showAlert("success", "New Car Type Added!");
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        console.error(e);
      }
    },
    add() {
      this.fetchCarModel();
      this.showAlert("success", "Add Car Model Successfully");
      this.showSnack("Add Car Model Successfully", "success");
    },
    showSnack(msg, color) {
      this.snackMessage = msg;
      this.color = color;
      this.snackbar = true;
    },
    chooseImage() {
      this.addCarModel.logoName = this.imgFile.name;
      this.imgUrl = URL.createObjectURL(this.imgFile);
    },
    searchType() {
      this.fetchCarModel();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCars();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchCars();
    },
    fetchCarModel() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.nameSearch,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/carModel`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.carModel = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(function(error) {
          self.showAlert("error", error);
        });
    },
    fetchCarBrand() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/carBrand?page=0&size=100&searchContent=`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.carBrandList = res.data.data.content;
        }
      });
    },
    fetchCarType() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/carType?page=0&size=100&searchContent=`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.carTypeList = res.data.data.content;
        }
      });
    },
    fetchFuelType() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/fuelType?page=0&size=100&searchContent=`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.carFuelTypeList = res.data.data.content;
        }
      });
    },
    fetchCOECat() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/coeCategoryPair`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.coeCatList = res.data.data.content;
        }
      });
    },
    fetchVes() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/generic/vesCategory`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.vesCategoryList = res.data.data;
        }
      });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },

    onToggleCheck(carModelId) {
      var self = this;
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/checkCarModel?carModelId=${carModelId}`,
        {},
        self.token
      )
        .then(res => {
          if (res.data) {
            // this.extrasDialog = false;
            this.fetchCarModel();
            self.showAlert("success", "Car Model Checked Successfully!");
          }
        })
        .catch(e => {
          this.checkToggle = false;
          self.showAlert("error", e);
        });
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
</style>
